const businessServicesBtns = document.querySelectorAll("#business_services_btns button");
const streetlineSec = document.querySelector("#streetline_sec");
const dedicatedDriversSec = document.querySelector("#dedicated_drivers_sec")


for(let i = 0; i < businessServicesBtns.length; i++) {
    const button = businessServicesBtns[i];
    button.addEventListener("click", (e) => {
        if (e.target.parentNode == button) {
            businessServicesBtns.forEach(btn => {
                btn.querySelector("#line").className = "block w-1 min-h-full bg-[#1A1D1F] rounded-full";
                btn.querySelector("#text").className = "text-[#383F45] text-xl flex gap-2";
            })

            button.querySelector("#line").className = "block w-1.5 min-h-full bg-white rounded-full";
            button.querySelector("#text").className = "p-1.5 flex gap-2 text-xl font-medium text-white cursor-pointer";


            if (e.target.innerHTML === "Streetline ( On-Demand)") {
                streetlineSec.className = "flex flex-col"
                dedicatedDriversSec.className = "hidden"

            } else {
                dedicatedDriversSec.className = "flex flex-col"
                streetlineSec.className = "hidden"

            }
        }
    })
}

const openModalBtn = document.querySelector("#streetline_sec #open_modal_btn");
const modal = document.querySelector("#streetline_modal");
const closeModalBtn = document.querySelector("#streetline_modal #close-modal");

openModalBtn.addEventListener("click", (e) => {
    modal.className = "fixed z-50 md:overflow-hidden overflow-scroll md:h-auto h-screen -translate-x-1/2 left-1/2 transition-all md:w-4/5 w-full top-1/2 duration-300 -translate-y-1/2 bg-[#0A112D]/50 rounded-[32px] backdrop-blur-3xl p-16 "
})

closeModalBtn.addEventListener("click", () => {
    modal.className = "fixed z-50 md:overflow-hidden overflow-scroll md:h-auto h-screen -translate-x-1/2 left-1/2 transition-all  md:w-4/5 w-full top-1/2 duration-300 -translate-y-[500%] bg-[#0A112D]/50 rounded-[32px] backdrop-blur-3xl p-16 "

})